import React from "react"
import PageTemplate from "../templates/pages/pageTemplate"

import { Button } from "reactstrap"
const NotFoundPage = () => (
  <PageTemplate title="404: Not found">
    <div className="my-5" style={{ minHeight: "20rem" }}>
      <h3 className="mb-5">Seite konnte nicht gefunden werden...</h3>
      <a href="/" className="btn btn-secondary">
        Zurück zur Startseite
      </a>
    </div>
  </PageTemplate>
)

export default NotFoundPage
